<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新建角色' : '修改角色'"
    class="yt-dialog yt-dialog-default"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form ref="roleForm" :model="roleForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="roleForm.name" placeholder="请输入角色名称" />
        <div v-if="!ruleValidate.name.error" class="form-tip">不超过30个字</div>
      </el-form-item>
      <el-form-item label="权限" prop="permissions">
        <el-checkbox-group v-model="roleForm.permissions" class="permission-checkbox-group">
          <el-checkbox :key="permission.id" v-for="permission in allPermissions" :label="permission.id">{{ permission.description }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-Button @click="close" class="button">取消</el-Button>
      <el-Button type="primary" class="button" @click="dealRole('roleForm')" :loading="loading">确定</el-Button>
    </div>
  </el-dialog>
</template>
<script>
import permissionUtils from '../../../../util/permissionUtils'
import role from '@api/role'
import { mapActions } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    role: {
      type: Object
    },
    allPermissions: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    const that = this

    return {
      visible: this.value,
      loading: false,
      roleForm: {
        name: this.isAdd ? '' : this.role.name,
        permissions: this.isAdd
          ? []
          : this.role.permissionVoList.map(item => {
              return item.id
            })
      },
      ruleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.ruleValidate.name.error = true
              callback(new Error('角色名称不为空'))
            } else if (value.length > 30) {
              that.ruleValidate.name.error = true
              callback(new Error('长度不超过30个字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        permissions: {
          type: 'array',
          required: true,
          message: '权限不能为空',
          trigger: 'change'
        }
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    ...mapActions({
      addRole: 'role/addRole',
      modifyRole: 'role/modifyRole'
    }),
    dealRole(name) {
      this.$refs[name].validate(valid => {
        if (!valid) {
          return
        }

        this.loading = true

        let roleInfo = {}
        this.isAdd
          ? (roleInfo = {
              name: this.roleForm.name,
              permissionIds: this.roleForm.permissions
            })
          : (roleInfo = {
              roleName: this.roleForm.name,
              permissionIds: this.roleForm.permissions
            })

        if (!this.isAdd) {
          //修改需要多一项roleId
          roleInfo.roleId = this.role.id
          ;(roleInfo.newRoleName = this.roleForm.name), (roleInfo.oldRoleName = this.role.name), (roleInfo.permissionIds = this.roleForm.permissions)
        }
        let promise = this.isAdd ? this.addRole(roleInfo) : this.modifyRole(roleInfo)

        promise
          .then(res => {
            this.close()
            this.isAdd && this.$refs[name].resetFields()
            this.$emit('on-success', res)
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  },
  watch: {
    value(val) {
      this.visible = val
    }
  }
}
</script>
<style lang="less" scoped>
.permission-checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>
