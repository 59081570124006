<template>
  <div class="role-card-container">
    <div class="role-card-content">
      <div class="role-name">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-guanliyuan" />
        </svg>
        <div class="hoverS">
          <el-tooltip effect="dark" :disabled="role.name.length < 5" :content="role.name">
            <p>{{ role.name }}</p>
          </el-tooltip>
        </div>
      </div>
      <div class="role-permissions">
        <div>权限：</div>
        <div style="height: 120px;overflow: hidden;max-height: 120px">
          <vueScroll :ops="ops">
            <div :key="permission.id" v-for="permission in role.permissionVoList" class="role-permission">
              {{ permission.description }}
            </div>
          </vueScroll>
        </div>
      </div>
    </div>
    <div class="role-card-operation">
      <div @click="visible = true">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-bianji" />
        </svg>
        <span>修改</span>
      </div>
      <div class="icon-separator"></div>
      <div @click="deleteVisible = true">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-shanchu" />
        </svg>
        <span>删除</span>
      </div>
    </div>
    <RoleModal v-model="visible" :is-add="false" :role="role" :all-permissions="allPermissions" />
    <ConfirmDialog
      :value="deleteVisible"
      :title="`删除${role.name}`"
      :loading="deleteLoading"
      @on-ok="deleteRole"
      @on-cancel="roleClose"
      @on-cancel-end="afterRoleClose"
    >
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除角色会使得管理员与该角色的绑定关系失效， 您还要继续吗？
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal v-model="deleteVisible" :title="`确认删除角色“${role.name}”吗？`" :loading="deleteLoading" @on-ok="deleteRole">-->
    <!--      <span class="error">删除角色会使得管理员与该角色的绑定关系失效， 您还要继续吗？</span>-->
    <!--    </DeleteModal>-->
  </div>
</template>
<script>
import RoleModal from './RoleModal'
import permissionUtils from '../../../../util/permissionUtils'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import vueScroll from 'vuescroll'
import { mapActions } from 'vuex'
import YTIcon from '../../../common/YTIcon'
import role from '@api/role'
export default {
  props: {
    role: {
      type: Object,
      required: true
    },
    allPermissions: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: {
    RoleModal,
    vueScroll,
    ConfirmDialog,
    YTIcon
  },
  data: function() {
    return {
      visible: false,
      deleteVisible: false,
      deleteLoading: false,
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#fff',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      }
    }
  },
  methods: {
    ...mapActions({
      deleteRoleAction: 'role/deleteRole'
    }),
    roleClose() {
      this.deleteVisible = false
    },
    afterRoleClose() {
      this.deleteLoading = false
    },
    deleteRole() {
      this.deleteLoading = true

      this.deleteRoleAction(this.role.id)
        .then(res => {
          if (res.res === true) {
            this.deleteVisible = false
            this.$message.success('删除角色成功')
          }
        })
        .finally(() => {
          this.deleteLoading = false
        })
    }
  },
  computed: {
    permissionIconList: function() {
      return permissionUtils.getPermissionIconList(this.role.permissionVoList)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.role-card-container {
  display: inline-flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  border-radius: 4px;
  color: white;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
  transition: all @default-transition;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.3);
  }

  &.question-color {
    background-color: @question-color;
  }

  &.course-color {
    background-color: @course-color;
  }

  &.system-color {
    background-color: @system-color;
  }

  &.exam-color {
    background-color: @exam-color;
  }

  .role-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding: 60px 10px 0 20px;

    .role-name {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: @font-weight-normal;
    }

    .role-permissions {
      margin-top: 10px;
      font-size: 18px;
      font-weight: @font-weight-light;
      display: flex;

      .role-permission {
        svg {
          &.question-color {
            color: lighten(@question-color, 10%);
          }

          &.course-color {
            color: lighten(@course-color, 10%);
          }

          &.system-color {
            color: lighten(@system-color, 10%);
          }

          &.exam-color {
            color: lighten(@exam-color, 10%);
          }
        }
      }
    }
  }

  .role-card-operation {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: @font-weight-light;
    border-top: 1px solid #e5e7ea;

    & > div {
      flex: 1 1 auto;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;

      & svg {
        font-size: 18px;
        margin-right: 6px;
      }
    }

    .icon-separator {
      flex: 0 0 1px;
      width: 1px;
      height: 20px;
      background-color: #bdc3cb;
    }
  }
}
.section-container {
  ::v-deep .error input {
    border: 1px solid #e52436;

    &:focus {
      box-shadow: 0 0 0 2px rgba(229, 36, 54, 0.2);
    }
  }

  .chapter {
    font-size: 12px;
    color: @font-color-content;

    .title {
      height: 50px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid #eaedf1;
      transition: all @default-transition;
      display: flex;

      &:after {
        content: '';
        display: inline-block;
        clear: both;
      }

      .arrow {
        margin-right: 4px;
        cursor: pointer;
        transform: rotate(-90deg);
        transition: all @default-transition;
        fill: @font-color-content;

        &.spread {
          transform: rotate(0);
        }
      }

      .name {
        float: left;
        cursor: pointer;
      }

      .time {
        float: left;
      }

      .chapter-opt {
        display: none;
        float: right;

        svg {
          margin: 0 6px;
          cursor: pointer;
          color: #afbacb;
          transition: all @default-transition;

          &:hover {
            color: @primary-color;
          }
        }
      }

      &:hover {
        background-color: #f8f9fb;

        .chapter-opt {
          display: block;
        }
      }
    }
  }

  ::v-deep .chapter-add {
    padding: 14px 24px;
    cursor: pointer;
    font-size: 14px;
    color: @primary-color;
    transition: color @default-transition;
    border-bottom: 1px solid #eaedf1;
  }

  ::v-deep .ivu-input-suffix {
    width: auto;
  }

  ::v-deep .ivu-input-with-suffix {
    padding-right: 56px;
  }
} /* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.hoverS {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
